<template>
  <div class="speech-eidt-page">
    <div class="edit-box">
      <div class="edit-title weight500">
        {{ pageType === 'add' ? '添加' : '编辑' }}话术
      </div>
      <div class="edit-input">
        <van-field
          v-model="content"
          rows="5"
          label=""
          type="textarea"
          maxlength="800"
          show-word-limit
          autosize
          placeholder="请输入话术内容"
        />
      </div>
      <div class="edit-btn-box">
        <div class="edit-btn cancel weight500" @click="cancelEdit">取消</div>
        <div
          :class="['edit-btn', 'weight500', content ? 'primary' : 'disable']"
          @click="onCommit"
        >
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field } from 'vant'
import {
  addPersonalSpeech,
  editPersonalSpeech
} from '../../../../service/quickSend.service'
export default {
  name: 'speechEdit',
  components: {
    [Field.name]: Field
  },

  data() {
    return {
      pageType: 'add',
      sale_script_id: '',
      content: '',
      editItem: {}
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      console.log('重新进入页面')
      this.pageType = this.$route.query && this.$route.query.pageType
      if (this.pageType === 'edit') {
        let obj = sessionStorage.getItem('editSpeech')
        this.editItem = JSON.parse(obj)
        this.sale_script_id = this.editItem.sale_script_id
        this.content = this.editItem.content
      }
    },
    // 添加或编辑话术
    async onCommit() {
      if (!this.content) {
        return
      }
      if (this.pageType == 'add') {
        await addPersonalSpeech({ content: this.content })
      } else if (this.pageType == 'edit') {
        await editPersonalSpeech({
          sale_script_id: this.sale_script_id,
          content: this.content
        })
      }
      // this.$router.go(-1)
      this.$router.push({
        path: '/marketing-personal_speech',
        query: {
          acId: 'personal_speech',
          editedId: this.sale_script_id
        }
      })
    },

    // 取消编辑
    cancelEdit() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/marketing-personal_speech',
        query: {
          acId: 'personal_speech',
          editedId: this.sale_script_id || ''
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.speech-eidt-page {
  padding-top: 8px;
  // 输入框样式更改
  .van-field {
    border-radius: 5px;
  }

  .edit-box {
    padding: 16px 15px;
    background-color: #fff;
    overflow: hidden;

    .edit-title {
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 8px;
    }

    .edit-input {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
    }
  }

  // 按钮
  .edit-btn-box {
    display: flex;
    padding-top: 15px;
    .edit-btn {
      line-height: 44px;
      flex: 1;
      font-size: 14px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
    }
    .edit-btn + .edit-btn {
      margin-left: 15px;
    }
    .primary {
      background-color: #1773fa;
      color: #fff;
    }
    .cancel {
      background-color: #f5f7fa;
      color: #999999;
    }
    .disable {
      background-color: #8bb9fd;
      color: #fff;
    }
  }
}
</style>
